import * as React from "react";
import { Link } from "gatsby";

const MaintenancePage = () => (
  <div className="h-250 bg-welcome relative">
    <div className="mx-auto text-center">
      <h1 className="text-6xl pt-72 font-bold">Site Under Maintenance</h1>
      <p className="text-3xl text-yellow-500 font-bold">
        Come back later
        <Link className="cursor-default" to="/">
          !
        </Link>
      </p>
    </div>
  </div>
);

export default MaintenancePage;
